import {request} from "@/core/services/Request";
import Error from "@/core/services/Error";

const DEFAULT_FORM_STATE = {
    title: null,
    description: null,
    lead_id: null,
    event_type: null,
    event_from_date: null,
    event_to_date: null,
    no_of_guest: null,
    client_budget: null,
    event_status: null,
    event_id: null,
    _method: 'post',
};

const SHARE_BOUNCE_EVENT_DEFAULT_FORM_STATE = {
    state_id: undefined,
    user_id: undefined,
    rate: null,
}

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Lead",
        sortable: false,
        value: 'lead_value',
    },
    {
        text: "Event Name",
        sortable: false,
        value: 'title',
    },
    {
        text: "From - To Date",
        sortable: false,
        value: 'date',
    },
    {
        text: "Type Of Event",
        sortable: false,
        value: 'event_type',
    },
    {
        text: "No. of guest",
        sortable: false,
        value: 'no_of_guest',
    },
    {
        text: "Client Budget",
        sortable: false,
        value: 'client_budget',
    },
    {
        text: "Rate",
        sortable: false,
        value: 'rate',
    },
    {
        text: "Description",
        sortable: false,
        value: 'description',
    },
    {
        text: "Action",
        value: "action",
        width: 150,
    },

];


export default {
    data() {
        return {
            operationTitle: 'Buy New Business here.',
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            listUrl: '/events/bounce/spu/share',
            columns: COLUMN_DEFINATION(this),
            dropdowns: {
                users: [],
                leads: [],
                eventType: [],
                dropAnalytics: [
                    {value: 'Dates not available', text: 'Dates not available'},
                    {value: 'Already Booked somewhere else', text: 'Already Booked somewhere else'},
                    {value: 'Budget Problem', text: 'Budget Problem'},
                    {value: 'Rent is too high', text: 'Rent is too high'},
                    {value: 'Per plate rate is too high', text: 'Per plate rate is too high'},
                    {value: 'Decoration rate is too much', text: 'Decoration rate is too much'},
                    {value: 'Location problem', text: 'Location problem'},
                    {value: 'Did not like the venue', text: 'Did not like the venue'},
                    {value: 'Self Catering', text: 'Self Catering'},
                    {value: 'Unavailability of Non Veg', text: 'Unavailability of Non Veg'},
                    {value: 'Wants pure veg', text: 'Wants pure veg'},
                    {value: 'Unavailability of Rooms', text: 'Unavailability of Rooms'},
                    {value: 'Unavailability of Drinks', text: 'Unavailability of Drinks'},
                    {value: 'Unavailability of Air Condition', text: 'Unavailability of Air Condition'},
                    {value: 'Unavailability of Parking', text: 'Unavailability of Parking'},
                    {value: 'Hygine Problem', text: 'Hygine Problem'},
                    {value: 'Looking for a bigger Venue', text: 'Looking for a bigger Venue'},
                    {value: 'Death in the family', text: 'Death in the family'},
                    {value: 'Marriage Cancelled', text: 'Marriage Cancelled'},
                    {value: 'Event Cancelled', text: 'Event Cancelled'},
                ],
                states: [],
                superUsers: [],
            },
            shareBounceEvent: {...SHARE_BOUNCE_EVENT_DEFAULT_FORM_STATE},
            selectAllUsers: false,
            shareEventDetail: []
        }
    },
    methods: {
        dateDisabled(ymd, date) {
            var myCurrentDate = new Date(date);
            var myPastDate = new Date(myCurrentDate);
            myPastDate.setDate(myPastDate.getDate() + 1);

            return (myPastDate < new Date(this.filters.from_date))
        },
        async handleSubmitShareBounceEventBuyNowOperation(item) {
            let deleteResponse = await this.$root.$confirm('Buy Event Confirmation', "Are you sure want to buy this event  ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        url: `/events/bounce/spu/share/buy/now`,
                        method: 'post',
                        data: {
                            "id": item.id,
                        }
                    })
                    this.$global.itemAdded("Event Buy")
                    this.loadList(this.listQueryParams)
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        if (JSON.parse(error.request.responseText).message) {
                            this.$global.handleServerError(JSON.parse(error.request.responseText))
                        }
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                    this.$global.handleServerError(error)
                }
            }
        },
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? 'events/bounce/update' : 'events/bounce/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }

        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/events/bounce/spu/share/detail/${id}`,
                })
                const {data} = response.data
                this.formFields = {
                    ...data,
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        // async handleDeleteOperation(id) {
        //     if (confirm('are you sure?')) {
        //         try {
        //             const response = await request({
        //                 method: 'post',
        //                 url: '/events/bounce/delete',
        //                 data: {
        //                     id: id,
        //                 },
        //             })
        //             this.loadList(this.listQueryParams)
        //             this.$global.itemDeleted()
        //         } catch (errors) {
        //             this.$global.handleServerError(errors)
        //         }
        //     }
        // },
        async getLeads() {
            try {
                const response = await request({
                    url: `/dropdowns/all/leads`,
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.leads = data.map((item) => {
                    return {
                        id: item.id,
                        label: item.first_name + item.last_name + " (" + item.mobile_number + ")"
                    }
                })
            } catch (e) {

            }
        },
        async getEventType() {
            try {
                const response = await request({
                    url: '/dropdowns/event/type',
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.eventType = data.map((item) => {
                    return {
                        id: item,
                        label: item,
                    }
                })
            } catch (e) {

            }
        },
        handleSelectAllUsers() {
            if (this.selectAllUsers) {
                this.shareBounceEvent.user_id = _.map(this.dropdowns.superUsers, (i) => i.id)
            } else {
                this.shareBounceEvent = []
            }
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "spuShareBounceEventList"})
        },
    },
}
