<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <b-button
                                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                                v-b-toggle.sidebar-backdrop-share-event>
                                <i class="fa fa-filter fa-sm"></i> Filter

                            </b-button>
                        </b-input-group-append>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="rooms-table"
                                 v-if="$global.hasPermission('sharebounceeventsview')">
                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-5">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select :options="[10, 50, 100]"
                                                               class="datatable-select"
                                                               size="sm"
                                                               v-model="pagination.totalPerPage"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <p v-if="global.pendingRequests > 0">
                                                <i class="fa fa-spinner"></i>
                                            </p>
                                        </div>
                                        <div class="col-sm-12 col-md-5">
                                            <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                                                <b-form-input class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              type="search"
                                                              v-model="search"
                                                              v-on:keyup.enter="handleSearch"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.lead_value="{item}">
                                        ****
                                    </template>
                                    <template v-slot:item.rate="{item}">
                                        {{ $global.numberToLocationString(item.rate) }}
                                    </template>
                                    <template v-slot:item.client_budget="{item}">
                                        {{ $global.numberToLocationString(item.client_budget) }}
                                    </template>
                                    <template v-slot:item.date="{item}">
                                        {{ $global.dateFormat(item.event_from_date) }} <br/>
                                        {{ $global.dateFormat(item.event_to_date) }}
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <b-dropdown
                                            no-caret size="sm" toggle-class="text-decoration-none"
                                            v-if="!item.type"
                                            variant="link">
                                            <template #button-content>
                                                <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                     <span
                                                         class="svg-icon svg-icon-md svg-icon-primary m-0">
                                                      <!--begin::Svg Icon-->
                                                      <inline-svg
                                                          class="action-edit-button"
                                                          src="/media/svg/icons/General/Settings-1.svg"
                                                      ></inline-svg>
                                                         <!--end::Svg Icon-->
                                                    </span>
                                                </p>
                                            </template>
                                            <b-dropdown-item
                                                @click="handleSubmitShareBounceEventBuyNowOperation(item)"
                                                href="#">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-wallet mr-2"></i> Buy Now
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <!--                                        <router-link :to="`/spu/share/bounce/events/edit/${item.id}`">-->
                                        <!--                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"-->
                                        <!--                                               v-if="$global.hasPermission('sharebounceeventsupdate') && !item.status">-->
                                        <!--                                                <span-->
                                        <!--                                                    class="svg-icon svg-icon-md svg-icon-primary">-->
                                        <!--                                                  &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
                                        <!--                                                  <inline-svg-->
                                        <!--                                                      src="/media/svg/icons/Communication/Write.svg"-->
                                        <!--                                                      class="action-edit-button"-->
                                        <!--                                                  ></inline-svg>-->
                                        <!--                                                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                                        <!--                                                </span>-->
                                        <!--                                            </a>-->
                                        <!--                                        </router-link>-->
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>
                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>
                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-share-event"
                            shadow
                            title="Filters"
                            right
                        >
                            <template #header="{ hide }">
                                <b-col sm="10">
                                    <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                                </b-col>
                                <b-col sm="2">
                                    <button @click="hide();"
                                            class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </button>
                                </b-col>
                            </template>
                            <template #footer="{ hide }">
                                <div
                                    class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                                    <b-button @click="hide();handleResetFilterClick()"
                                              class="btn btn-light-primary font-weight-bolder ml-2">
                                        <i class="fa fa-broom fa-sm"></i> Clear All
                                    </b-button>
                                </div>
                            </template>
                            <div class="px-10 py-4">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Start Date"
                                        label-for="from_date">
                                        <b-form-datepicker
                                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                            close-button
                                            reset-button
                                            today-button
                                            v-model="filters.from_date"
                                        >
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        label="End Date"
                                        label-for="to_date">
                                        <b-form-datepicker
                                            :date-disabled-fn="dateDisabled"
                                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                            :disabled="!filters.from_date"
                                            close-button
                                            reset-button
                                            today-button
                                        >
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col>
                            </div>
                        </b-sidebar>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import ModuleMixin from "./module.mixin"

    const DEFAULT_FILTER_STATE = {
        from_date: null,
        to_date: null,
    };

    export default {
        mixins: [ListingMixin, ModuleMixin],
        data() {
            return {
                filters: {...DEFAULT_FILTER_STATE},
            }
        },
        components: {
            KTCard
        },
        methods: {
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.loadList(this.listQueryParams)
            }
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
